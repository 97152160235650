<template>
  <v-app>
    <v-app-bar dense app dark color="accent">
      <v-btn icon v-if="$route.name !== 'home'" @click="$router.go(-1)">
        <v-icon>{{ arrowleft }}</v-icon>
      </v-btn>
      <v-img
        max-height="40"
        max-width="80"
        src="img/logo/KemnaLogo.png"
      ></v-img>
      <v-toolbar-title class="px-1"> Estimator</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mdiArrowLeft } from "@mdi/js";
/* eslint-disable */
import Home from "./components/DogHome";
export default {
  name: "App",
  components: {
    Home,
  },
  data() {
    return {
      arrowleft: mdiArrowLeft,
      //
    };
  },
};
/* eslint-enable */
</script>

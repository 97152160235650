import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../components/Home.vue")
  },
  {
    path: "/dog",
    name: "doghome",
    component: () =>
      import(/* webpackChunkName: "doghome" */ "../components/DogHome.vue")
  },
  {
    path: "/dog/details/:id",
    name: "details",
    props: true,
    component: () =>
      import(/* webpackChunkName: "details" */ "../components/Details.vue")
  },
  {
    path: "/dog/post",
    name: "post",
    component: () =>
      import(/* webpackChunkName: "post" */ "../components/Post.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;

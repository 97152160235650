import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// iconfont stuff untested, try to remove and see if it stil works
export default new Vuetify({
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        //        primary: '#3f51b5',
        //        secondary: '#b0bec5',
        accent: "#1565C0"
        //        error: '#b71c1c',
      }
    }
  }
});
